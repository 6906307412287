import { CellStyle, ColDef, ColGroupDef } from "ag-grid-community";
import TimeCellEditor from "./TimeCellEditor";
import { CustomCellRenderer } from "../../../utils/helpers";

const BlankCellRenderer = ({ value }: any) => (value !== null ? value : "-");

const nonEditableCells = [
  "Total Scheduled Hours",
  // "Expected Clocked Hours",
  "Planned Hours",
  "Over/(Under) Planned Hours",
];

export const COLUMN_DEFS: (ColDef<any> | ColGroupDef<any>)[] = [
  {
    field: "employeeName",
    headerName: "Employee",
    minWidth: 140,
    headerClass: ["custom-header-text", "schedule-grid-header-cell"],
    pinned: "left",
    cellClass: "custom-grid-cell",
    editable: (params) => !nonEditableCells.includes(params.data.employeeName),
    colSpan: (params) =>
      params.data.isBlankRow ? 24 : params.data.twoColspan ? 2 : 1,
    checkboxSelection: true,
    headerCheckboxSelection: true,
    tooltipField: "employeeName",
    cellStyle: {
      justifyContent: "flex-start",
    },
  },
  {
    field: "jobName",
    headerName: "Position",
    minWidth: 100,
    headerClass: [
      "custom-header-text",
      "custom-header-left-border",
      "schedule-grid-header-cell",
    ],
    pinned: "left",
    cellClass: "custom-grid-cell",
    editable: false,
  },
  {
    headerName: "Sunday",
    type: "centerAligned",
    headerClass: [
      "schedule-grid-header-group-cell",
      "custom-header-left-border",
    ],
    children: [
      {
        field: "sunday.inTime",
        headerName: "In",
        minWidth: 66,
        editable: true,
        headerClass: ["custom-header-left-border", "schedule-grid-header-cell"],
        colSpan: (params) => {
          if (params.data.isBlankRow) {
            return 21;
          } else if (params.data.twoColspan && !params.data.sunday.inTime) {
            return 2;
          }
          return 1;
        },
        cellEditor: TimeCellEditor,
        cellRenderer: BlankCellRenderer,
        cellClass: "custom-grid-cell",
        cellStyle: (params) => {
          if (params?.value === "Carry over") {
            return { fontSize: 12 } as CellStyle;
          } else if (
            params?.value === null &&
            params?.data?.sunday.outTime !== null
          ) {
            return { background: "#FFEAEA" } as CellStyle;
          } else if (params?.data?.sunday?.carryOverHoursInMinutes) {
            return { color: "#FF3130" } as CellStyle;
          }
          return null;
        },
      },
      {
        field: "sunday.outTime",
        headerName: "Out",
        minWidth: 66,
        headerClass: ["schedule-grid-header-cell"],
        editable: true,
        cellEditor: TimeCellEditor,
        cellRenderer: BlankCellRenderer,
        cellClass: "custom-grid-cell",
        cellStyle: (params) => {
          if (params?.value === null && params?.data?.sunday?.inTime !== null) {
            return { background: "#FFEAEA" } as CellStyle;
          } else if (params?.data?.sunday?.inTime === "Carry over") {
            return { background: "rgba(255, 145, 15, 0.20)" } as CellStyle;
          } else if (params?.data?.sunday?.carryOverHoursInMinutes) {
            return { color: "#FF3130" } as CellStyle;
          }
          return null;
        },
      },
      {
        field: "sunday.hoursText",
        headerName: "Hrs",
        minWidth: 66,
        cellClass: ["custom-grid-cell", "gray-cell"],
        headerClass: ["schedule-grid-header-cell"],
        editable: false,
        cellRenderer: CustomCellRenderer,
      },
    ],
  },
  {
    headerName: "Monday",
    headerClass: [
      "schedule-grid-header-group-cell",
      "custom-header-left-border",
    ],
    children: [
      {
        field: "monday.inTime",
        headerName: "In",
        minWidth: 66,
        headerClass: ["custom-header-left-border", "schedule-grid-header-cell"],
        colSpan: (params) => {
          if (params.data.twoColspan) {
            return 2;
          }
          return 1;
        },
        editable: true,
        cellEditor: TimeCellEditor,
        cellRenderer: BlankCellRenderer,
        cellClass: "custom-grid-cell",
        cellStyle: (params) => {
          if (
            params?.value === null &&
            params?.data?.monday?.outTime !== null
          ) {
            return { background: "#FFEAEA" } as CellStyle;
          } else if (params?.data?.monday?.carryOverHoursInMinutes) {
            return { color: "#FF3130" } as CellStyle;
          }
          return null;
        },
      },
      {
        field: "monday.outTime",
        headerName: "Out",
        minWidth: 66,
        headerClass: ["schedule-grid-header-cell"],
        editable: true,
        cellEditor: TimeCellEditor,
        cellRenderer: BlankCellRenderer,
        cellClass: "custom-grid-cell",
        cellStyle: (params) => {
          if (params?.value === null && params?.data?.monday?.inTime !== null) {
            return { background: "#FFEAEA" } as CellStyle;
          } else if (params?.data?.monday?.carryOverHoursInMinutes) {
            return { color: "#FF3130" } as CellStyle;
          }
          return null;
        },
      },
      {
        field: "monday.hoursText",
        headerName: "Hrs",
        minWidth: 66,
        cellClass: ["custom-grid-cell", "gray-cell"],
        headerClass: ["schedule-grid-header-cell"],
        editable: false,
        cellRenderer: CustomCellRenderer,
      },
    ],
  },
  {
    headerName: "Tuesday",
    headerClass: [
      "schedule-grid-header-group-cell",
      "custom-header-left-border",
    ],
    children: [
      {
        field: "tuesday.inTime",
        headerName: "In",
        minWidth: 66,
        headerClass: ["custom-header-left-border", "schedule-grid-header-cell"],
        colSpan: (params) => {
          if (params.data.twoColspan) {
            return 2;
          }
          return 1;
        },
        editable: true,
        cellEditor: TimeCellEditor,
        cellRenderer: BlankCellRenderer,
        cellClass: "custom-grid-cell",
        cellStyle: (params) => {
          if (
            params?.value === null &&
            params?.data?.tuesday?.outTime !== null
          ) {
            return { background: "#FFEAEA" } as CellStyle;
          } else if (params?.data?.tuesday?.carryOverHoursInMinutes) {
            return { color: "#FF3130" } as CellStyle;
          }
          return null;
        },
      },
      {
        field: "tuesday.outTime",
        headerName: "Out",
        minWidth: 66,
        headerClass: ["schedule-grid-header-cell"],
        editable: true,
        cellEditor: TimeCellEditor,
        cellRenderer: BlankCellRenderer,
        cellClass: "custom-grid-cell",
        cellStyle: (params) => {
          if (
            params?.value === null &&
            params?.data?.tuesday?.inTime !== null
          ) {
            return { background: "#FFEAEA" } as CellStyle;
          } else if (params?.data?.tuesday?.carryOverHoursInMinutes) {
            return { color: "#FF3130" } as CellStyle;
          }
          return null;
        },
      },
      {
        field: "tuesday.hoursText",
        headerName: "Hrs",
        minWidth: 66,
        cellClass: ["custom-grid-cell", "gray-cell"],
        headerClass: ["schedule-grid-header-cell"],
        editable: false,
        cellRenderer: CustomCellRenderer,
      },
    ],
  },
  {
    headerName: "Wednesday",
    headerClass: [
      "schedule-grid-header-group-cell",
      "custom-header-left-border",
    ],
    children: [
      {
        field: "wednesday.inTime",
        headerName: "In",
        minWidth: 66,
        headerClass: ["custom-header-left-border", "schedule-grid-header-cell"],
        colSpan: (params) => {
          if (params.data.twoColspan) {
            return 2;
          }
          return 1;
        },
        editable: true,
        cellEditor: TimeCellEditor,
        cellRenderer: BlankCellRenderer,
        cellClass: "custom-grid-cell",
        cellStyle: (params) => {
          if (
            params?.value === null &&
            params?.data?.wednesday?.outTime !== null
          ) {
            return { background: "#FFEAEA" } as CellStyle;
          } else if (params?.data?.wednesday?.carryOverHoursInMinutes) {
            return { color: "#FF3130" } as CellStyle;
          }
          return null;
        },
      },
      {
        field: "wednesday.outTime",
        headerName: "Out",
        minWidth: 66,
        headerClass: ["schedule-grid-header-cell"],
        editable: true,
        cellEditor: TimeCellEditor,
        cellRenderer: BlankCellRenderer,
        cellClass: "custom-grid-cell",
        cellStyle: (params) => {
          if (
            params?.value === null &&
            params?.data?.wednesday?.inTime !== null
          ) {
            return { background: "#FFEAEA" } as CellStyle;
          } else if (params?.data?.wednesday?.carryOverHoursInMinutes) {
            return { color: "#FF3130" } as CellStyle;
          }
          return null;
        },
      },
      {
        field: "wednesday.hoursText",
        headerName: "Hrs",
        minWidth: 66,
        cellClass: ["custom-grid-cell", "gray-cell"],
        headerClass: ["schedule-grid-header-cell"],
        editable: false,
        cellRenderer: CustomCellRenderer,
      },
    ],
  },
  {
    headerName: "Thursday",
    headerClass: [
      "schedule-grid-header-group-cell",
      "custom-header-left-border",
    ],
    children: [
      {
        field: "thursday.inTime",
        headerName: "In",
        minWidth: 66,
        headerClass: ["custom-header-left-border", "schedule-grid-header-cell"],
        colSpan: (params) => {
          if (params.data.twoColspan) {
            return 2;
          }
          return 1;
        },
        editable: true,
        cellEditor: TimeCellEditor,
        cellRenderer: BlankCellRenderer,
        cellClass: "custom-grid-cell",
        cellStyle: (params) => {
          if (
            params?.value === null &&
            params?.data?.thursday?.outTime !== null
          ) {
            return { background: "#FFEAEA" } as CellStyle;
          } else if (params?.data?.thursday?.carryOverHoursInMinutes) {
            return { color: "#FF3130" } as CellStyle;
          }
          return null;
        },
      },
      {
        field: "thursday.outTime",
        headerName: "Out",
        minWidth: 66,
        headerClass: ["schedule-grid-header-cell"],
        editable: true,
        cellEditor: TimeCellEditor,
        cellRenderer: BlankCellRenderer,
        cellClass: "custom-grid-cell",
        cellStyle: (params) => {
          if (
            params?.value === null &&
            params?.data?.thursday?.inTime !== null
          ) {
            return { background: "#FFEAEA" } as CellStyle;
          } else if (params?.data?.thursday?.carryOverHoursInMinutes) {
            return { color: "#FF3130" } as CellStyle;
          }
          return null;
        },
      },
      {
        field: "thursday.hoursText",
        headerName: "Hrs",
        minWidth: 66,
        cellClass: ["custom-grid-cell", "gray-cell"],
        headerClass: ["schedule-grid-header-cell"],
        editable: false,
        cellRenderer: CustomCellRenderer,
      },
    ],
  },
  {
    headerName: "Friday",
    headerClass: [
      "schedule-grid-header-group-cell",
      "custom-header-left-border",
    ],
    children: [
      {
        field: "friday.inTime",
        headerName: "In",
        minWidth: 66,
        headerClass: ["custom-header-left-border", "schedule-grid-header-cell"],
        colSpan: (params) => {
          if (params.data.twoColspan) {
            return 2;
          }
          return 1;
        },
        editable: true,
        cellEditor: TimeCellEditor,
        cellRenderer: BlankCellRenderer,
        cellClass: "custom-grid-cell",
        cellStyle: (params) => {
          if (
            params?.value === null &&
            params?.data?.friday?.outTime !== null
          ) {
            return { background: "#FFEAEA" } as CellStyle;
          } else if (params?.data?.friday?.carryOverHoursInMinutes) {
            return { color: "#FF3130" } as CellStyle;
          }
          return null;
        },
      },
      {
        field: "friday.outTime",
        headerName: "Out",
        minWidth: 66,
        headerClass: ["schedule-grid-header-cell"],
        editable: true,
        cellEditor: TimeCellEditor,
        cellRenderer: BlankCellRenderer,
        cellClass: "custom-grid-cell",
        cellStyle: (params) => {
          if (params?.value === null && params?.data?.friday?.inTime !== null) {
            return { background: "#FFEAEA" } as CellStyle;
          } else if (params?.data?.friday?.carryOverHoursInMinutes) {
            return { color: "#FF3130" } as CellStyle;
          }
          return null;
        },
      },
      {
        field: "friday.hoursText",
        headerName: "Hrs",
        minWidth: 66,
        cellClass: ["custom-grid-cell", "gray-cell"],
        headerClass: ["schedule-grid-header-cell"],
        editable: false,
        cellRenderer: CustomCellRenderer,
      },
    ],
  },
  {
    headerName: "Saturday",
    headerClass: [
      "schedule-grid-header-group-cell",
      "custom-header-left-border",
    ],
    children: [
      {
        field: "saturday.inTime",
        headerName: "In",
        minWidth: 66,
        headerClass: ["custom-header-left-border", "schedule-grid-header-cell"],
        colSpan: (params) => {
          if (params.data.twoColspan && !params.data.saturday.inTime) {
            return 2;
          }
          return 1;
        },
        editable: true,
        cellEditor: TimeCellEditor,
        cellRenderer: BlankCellRenderer,
        cellClass: "custom-grid-cell",
        cellStyle: (params) => {
          if (params?.value === "Carry forward") {
            return { fontSize: 11 } as CellStyle;
          } else if (
            params?.value === null &&
            params?.data?.saturday?.outTime !== null
          ) {
            return { background: "#FFEAEA" } as CellStyle;
          } else if (params?.data?.saturday?.carryOverHoursInMinutes) {
            return { color: "#FF3130" } as CellStyle;
          }
          return null;
        },
      },
      {
        field: "saturday.outTime",
        headerName: "Out",
        minWidth: 66,
        headerClass: ["schedule-grid-header-cell"],
        editable: true,
        cellEditor: TimeCellEditor,
        cellRenderer: BlankCellRenderer,
        cellClass: "custom-grid-cell",
        cellStyle: (params) => {
          if (
            params?.value === null &&
            params?.data?.saturday?.inTime !== null
          ) {
            return { background: "#FFEAEA" } as CellStyle;
          } else if (params?.data?.saturday?.inTime === "Carry forward") {
            return { background: "#CCE7C0" } as CellStyle;
          } else if (params?.data?.saturday?.carryOverHoursInMinutes) {
            return { color: "#FF3130" } as CellStyle;
          }
          return null;
        },
      },
      {
        field: "saturday.hoursText",
        headerName: "Hrs",
        minWidth: 66,
        cellClass: ["custom-grid-cell", "gray-cell"],
        headerClass: ["schedule-grid-header-cell"],
        editable: false,
        cellRenderer: CustomCellRenderer,
      },
    ],
  },
  {
    field: "weeklyTotalHoursText",
    headerName: "Weekly Total",
    wrapHeaderText: true,
    minWidth: 84,
    headerClass: [
      "custom-header-text",
      "custom-header-left-border",
      "schedule-grid-header-cell",
    ],
    pinned: "right",
    cellClass: "custom-grid-cell",
    editable: false,
    cellRenderer: CustomCellRenderer,
    cellStyle: (params) => {
      if (params.data.weeklyTotalHoursInMinutes > 2400) {
        return { background: "#FFE4AC" };
      }
      return null;
    },
  },
];
