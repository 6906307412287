import { useAccount } from "@azure/msal-react";
import { Add, Close, SaveOutlined, WorkOffOutlined } from "@mui/icons-material";
import {
  Button,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  Skeleton,
  Stack,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  styled,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { TimeField } from "@mui/x-date-pickers/TimeField";
import { format } from "date-fns";
import _ from "lodash";
import { useContext, useEffect, useState } from "react";
import DELETE_ICON from "../../assets/icons/Icon-delete.svg";
import CHECK_CIRCLE_ICON from "../../assets/icons/check-circle.svg";
import {
  CustomInput,
  CustomSelect,
  CustomSwitch,
  Spinner,
} from "../../components";
import regionService from "../../services/regionService";
import storeService from "../../services/storeService";
import { FLOAT_REGEX, UserRole } from "../../utils/constants";
import { HomeContext } from "../Home";
import {
  CHIP_WRAPPER,
  CONTENT_BOX,
  SWITCH_LABEL,
  TABLE_CELL_WRAPPER,
  TABLE_ROW_WRAPPER,
} from "../Store/styles";
import { useSnackbar } from "notistack";
import React from "react";

const INPUT_LABEL: SxProps = {
  fontWeight: 500,
  fontSize: 12,
  lineHeight: "16px",
  letterSpacing: "0.4px",
  color: "#595959",
};

const SECTION_HEADER: SxProps = {
  fontSize: 16,
  fontWeight: 500,
  lineHeight: "24px",
  color: "#061E23",
};

const ADD_BTN: SxProps = {
  borderRadius: "4px",
  height: 40,
  border: "1px solid #CEE8EF",
  background: "#F4FAFC",
  textTransform: "capitalize",
  fontSize: 12,
  fontWeight: 500,
  lineHeight: "24px",
  color: "#383838",
  boxShadow: "none",
  padding: "8px 16px 8px 8px",
  "&:hover": {
    border: "1px solid #CEE8EF",
    background: "#F4FAFC",
  },
};

const CANCEL_BTN: SxProps = {
  borderRadius: "4px",
  height: 40,
  border: "none",
  background: "rgba(6, 30, 35, 0.08)",
  textTransform: "capitalize",
  fontSize: 14,
  fontWeight: 500,
  lineHeight: "20px",
  color: "#061E23",
  boxShadow: "none",
  padding: "10px 16px",
  "&:hover": {
    border: "none",
    background: "rgba(6, 30, 35, 0.08)",
  },
};

const SAVE_BTN: SxProps = {
  borderRadius: "4px",
  height: 40,
  border: "1px solid #204EC4",
  background: "#204EC4",
  textTransform: "capitalize",
  fontSize: 12,
  fontWeight: 500,
  lineHeight: "24px",
  color: "#FFFFFF",
  boxShadow: "none",
  padding: "10px 16px",
  "&:hover": {
    border: "1px solid #204EC4",
    background: "#204EC4",
  },
};

const NO_DATA_TEXT: SxProps = {
  fontSize: 14,
  fontWeight: 400,
  color: "#595959",
};

const WeekdayToggle = styled(ToggleButton)(({ theme }) => ({
  "&.Mui-selected": {
    backgroundColor: "rgba(187, 239, 255, 0.74)",
  },
  backgroundColor: "rgba(6, 30, 35, 0.10)",
  borderRadius: "50% !important",
  width: 40,
  height: 40,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  color: "#061E23",
  fontSize: 12,
  fontWeight: 400,
  lineHeight: "18px",
  marginRight: theme.spacing(1.5),
  border: "none",
}));

const initialHours = [
  {
    storeHoursId: 0,
    storeId: 0,
    day: "Sunday",
    opensAt: "00:00",
    closeAt: "00:00",
  },
  {
    storeHoursId: 0,
    storeId: 0,
    day: "Monday",
    opensAt: "00:00",
    closeAt: "00:00",
  },
  {
    storeHoursId: 0,
    storeId: 0,
    day: "Tuesday",
    opensAt: "00:00",
    closeAt: "00:00",
  },
  {
    storeHoursId: 0,
    storeId: 0,
    day: "Wednesday",
    opensAt: "00:00",
    closeAt: "00:00",
  },
  {
    storeHoursId: 0,
    storeId: 0,
    day: "Thursday",
    opensAt: "00:00",
    closeAt: "00:00",
  },
  {
    storeHoursId: 0,
    storeId: 0,
    day: "Friday",
    opensAt: "00:00",
    closeAt: "00:00",
  },
  {
    storeHoursId: 0,
    storeId: 0,
    day: "Saturday",
    opensAt: "00:00",
    closeAt: "00:00",
  },
];

const initialValues = {
  isActive: true,
  storeCode: "",
  regionId: 0,
  regionName: "",
  regionManagerName: "",
  regionManagerEmail: "",
  districtManagerName: "",
  districtManagerEmail: "",
  sm1name: "",
  sm1email: "",
  sm2name: "",
  sm2email: "",
  address1: "",
  address2: "",
  state: "",
  city: "",
  zipCode: "",
  minimumStaffingLevel: null,
  storeFeatures: [],
  deletedStoreFeatureIds: [],
  storeJobs: [],
  deletedStoreJobsIds: [],
  storeHours: initialHours,
};

const weekdays = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const CreateStoreForm = ({
  onCancel,
  isEnable = false,
  storeData = null,
  onSubmit,
}: any) => {
  // const regionContextData = useContext(RegionContext) || null;
  const account = useAccount();
  const { currentRole } = useContext(HomeContext);
  const { enqueueSnackbar } = useSnackbar();

  const [values, setValues] = useState({ ...initialValues, ...storeData });
  const [regions, setRegions] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [selectedDay, setSelectedDay] = useState(weekdays[0]);
  const [managers, setManagers] = useState<any>(null);
  const [jobs, setJobs] = useState<any[]>([]);
  const [services, setServices] = useState<any[]>([]);
  const [filteredManagers, setFilteredManagers] = useState({
    rmUsers: [],
    dmUsers: [],
    smUsers: [],
  });
  const [newJob, setNewJob] = useState({
    serviceId: 0,
    serviceName: null,
    jobId: 0,
    jobName: null,
  });
  const [selectedFeature, setSelectedFeature] = useState<any>(null);
  const [validationErrors, setValidationErrors] = useState<any>({});
  const [openHours, setOpenHours] = useState<any>(new Date());
  const [closeHours, setCloseHours] = useState<any>(new Date());

  const getDistrictManagers = async (regionId: number, tempManagers: any) => {
    try {
      await storeService
        .getManagersByRegionId(regionId)
        .then(async (response) => {
          if (response?.data) {
            setFilteredManagers({
              ...tempManagers,
              dmUsers: response?.data?.dmUsers,
              smUsers: response?.data?.smUsers,
            });
          }
        })
        .catch((error) => {
          console.error("Error fetching managers:", error);
        });
    } catch (error) {
      console.error("[getDistrictManagers]: ", error);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    fetchManagers();
    fetchJobs();
    fetchServices();
    setIsLoading(false);

    if (_.isEmpty(values?.storeHours)) {
      if (storeData) {
        const updatedHours = initialHours.map((hour) => ({
          ...hour,
          storeId: values?.storeId,
        }));
        setValues({ ...values, storeHours: updatedHours });
      } else {
        setValues({ ...values, storeHours: initialHours });
      }

      const sundayHours = initialHours[0];
      const currentDate = new Date().toISOString().slice(0, 10);
      const openDateTimeString = currentDate + `T${sundayHours.opensAt}:00`;
      const openDateObject = new Date(openDateTimeString);
      const closeDateTimeString = currentDate + `T${sundayHours.closeAt}:00`;
      const closeDateObject = new Date(closeDateTimeString);

      setOpenHours(openDateObject);
      setCloseHours(closeDateObject);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (storeData) {
      if (!_.isEmpty(values?.storeHours)) {
        const sundayHours = values?.storeHours[0];
        const currentDate = new Date().toISOString().slice(0, 10);
        const openDateTimeString = currentDate + `T${sundayHours.opensAt}:00`;
        const openDateObject = new Date(openDateTimeString);
        const closeDateTimeString = currentDate + `T${sundayHours.closeAt}:00`;
        const closeDateObject = new Date(closeDateTimeString);

        setOpenHours(openDateObject);
        setCloseHours(closeDateObject);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeData]);

  useEffect(() => {
    setFilteredManagers(managers);
  }, [managers]);

  const fetchManagers = async () => {
    try {
      await regionService
        .getDistrictsByRegion()
        .then(async (response) => {
          if (response?.data) {
            const allManagers: any = {
              rmUsers: [],
              dmUsers: [],
              smUsers: [],
            };
            setRegions(response?.data);
            if (storeData) {
              setIsDataLoading(true);
              const findRM = response?.data.filter(
                (region: any) => region.regionId === values.regionId,
              );
              allManagers.rmUsers = findRM;
              await getDistrictManagers(values.regionId, allManagers);
              setIsDataLoading(false);
            } else {
              allManagers.rmUsers = response?.data;
              setManagers(allManagers);
            }

            setTimeout(() => {
              if (currentRole === UserRole.RegionalManagers) {
                debugger;
                const findRegion = response?.data.find((region: any) => {
                  const cleanedRegionEmail = region.email?.trim().toLowerCase();
                  const cleanedAccountEmail = account?.username
                    ?.trim()
                    .toLowerCase();
                  return cleanedRegionEmail === cleanedAccountEmail;
                });
                setRegions([...response?.data, findRegion]);
                handleROMRegionChange(findRegion, response?.data);
              }
            });
          }
        })
        .catch((error) => {
          console.error("Error fetching managers:", error);
        });
    } catch (error) {
      setIsLoading(false);
      console.error("[fetchManagers]: ", error);
    }
  };

  const fetchJobs = async () => {
    try {
      await storeService
        .getJobs()
        .then(async (response) => {
          if (response?.data) {
            setJobs(response?.data);
          }
        })
        .catch((error) => {
          console.error("Error fetching jobs:", error);
        });
    } catch (error) {
      console.error("[fetchJobs]: ", error);
      setIsLoading(false);
    }
  };

  const fetchServices = async () => {
    try {
      await storeService
        .getServices()
        .then(async (response) => {
          if (response?.data) {
            setServices(response?.data);
            // if (!storeData) {
            //   const normalizedFilterArray = DEFAULT_SERVICES.map(
            //     (name: string) => name.toLowerCase().replace(/\s/g, "")
            //   );

            //   // Filter the original array
            //   const filteredArray = response?.data.filter((item: any) =>
            //     normalizedFilterArray.includes(
            //       item.name.toLowerCase().replace(/\s/g, "")
            //     )
            //   );

            //   const transformedArray = filteredArray.map((item: any) => ({
            //     id: 0,
            //     storeId: 0,
            //     serviceId: item.serviceId,
            //     serviceName: item.name,
            //     createdBy: account?.username || "",
            //     modifiedBy: account?.username || "",
            //   }));
            //   setValues({ ...values, storeFeatures: transformedArray });
            // }
          }
        })
        .catch((error) => {
          console.error("Error fetching services:", error);
        });
    } catch (error) {
      console.error("[fetchServices]: ", error);
      setIsLoading(false);
    }
  };

  const handleInputChange = (e: any, REGEX: any = null) => {
    const { name, value, checked, type } = e.target;

    if (REGEX && REGEX instanceof RegExp && value && !REGEX.test(value)) {
      return;
    }
    setValues((prevValues: any) => ({
      ...prevValues,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleRegionChange = async (val: any) => {
    setIsDataLoading(true);
    setValues({
      ...values,
      regionId: val?.regionId || 0,
      regionName: val?.name || "",
      regionManagerName: val?.managerName || "",
      regionManagerEmail: val?.email || "",
      districtManagerName: "",
      districtManagerEmail: "",
    });
    // Filter rmUsers and dmUsers based on regionId
    const filteredRmUsers = val
      ? filteredManagers?.rmUsers.filter(
          (user: any) => user.regionId === val.regionId,
        )
      : [];

    const tempUsers = _.clone(filteredManagers);
    tempUsers.rmUsers = filteredRmUsers;

    await getDistrictManagers(val?.regionId, tempUsers);
    setIsDataLoading(false);
  };

  const handleROMRegionChange = async (val: any, rmUsers: any) => {
    // Filter rmUsers and dmUsers based on regionId
    const filteredRmUsers = val
      ? rmUsers.filter((user: any) => user.regionId === val.regionId)
      : [];

    const tempUsers = _.clone(filteredManagers);
    tempUsers.rmUsers = filteredRmUsers;

    await getDistrictManagers(val?.regionId, tempUsers);

    setValues({
      ...values,
      regionId: val?.regionId || 0,
      regionName: val?.name || "",
      regionManagerName: filteredRmUsers[0]?.managerName || "",
      regionManagerEmail: filteredRmUsers[0]?.email || "",
      // districtManagerName: "",
      // districtManagerEmail: "",
    });
  };

  const handleRMChange = (val: any) => {
    setValues({
      ...values,
      regionManagerName: val?.managerName || "",
      regionManagerEmail: val?.email || "",
      districtManagerName: "",
      districtManagerEmail: "",
    });
  };

  const handleDMChange = (val: any) => {
    setValues({
      ...values,
      districtManagerName: val?.displayName || "",
      districtManagerEmail: val?.email || "",
      // sm1name: "",
      // sm1email: "",
      // sm2name: "",
      // sm2email: "",
    });
  };

  // const handleSM1Change = (val: any) => {
  //   setValues({
  //     ...values,
  //     sm1name: val?.displayName || "",
  //     sm1email: val?.email || "",
  //   });
  // };

  const handleSM2Change = (val: any) => {
    setValues({
      ...values,
      sm2name: val?.displayName || "",
      sm2email: val?.email || "",
    });
  };

  const handleServiceChange = (val: any) => {
    setNewJob({
      ...newJob,
      serviceId: val?.serviceId || 0,
      serviceName: val?.name || "",
    });
  };

  const handleJobChange = (val: any) => {
    setNewJob({
      ...newJob,
      jobId: val?.jobId || 0,
      jobName: val?.name || "",
    });
  };

  const onJobAdd = () => {
    setIsDataLoading(true);
    const tempJobData = {
      storeJobsId: 0,
      storeId: 0,
      serviceId: newJob?.serviceId,
      jobId: newJob?.jobId,
      isExcluded: true,
      jobName: newJob?.jobName,
      serviceName: newJob?.serviceName,
      createdBy: account?.username || "",
      modifiedBy: account?.username || "",
      createdDate: new Date().toISOString(),
      modifiedDate: new Date().toISOString(),
    };
    const tempJobs = [...values.storeJobs];
    tempJobs.push(tempJobData);
    setValues({ ...values, storeJobs: tempJobs });
    setNewJob({
      serviceId: 0,
      serviceName: null,
      jobId: 0,
      jobName: null,
    });
    setIsDataLoading(false);
  };

  const onJobDelete = (index: number) => {
    setIsDataLoading(true);
    const tempJobs = _.clone(values.storeJobs) || [];
    const deletedJobId = tempJobs[index];
    tempJobs.splice(index, 1);
    if (storeData) {
      setValues({
        ...values,
        storeJobs: tempJobs,
        deletedStoreJobsIds: [
          ...values.deletedStoreJobsIds,
          deletedJobId.storeJobsId,
        ],
      });
    } else {
      setValues({ ...values, storeJobs: tempJobs });
    }
    setIsDataLoading(false);
  };

  const onFeatureAdd = () => {
    setIsDataLoading(true);
    const tempFeatureData = {
      id: 0,
      storeId: 0,
      serviceId: selectedFeature?.serviceId || 0,
      serviceName: selectedFeature?.name || "",
      createdBy: account?.username || "",
      modifiedBy: account?.username || "",
    };
    const tempFeatures = [...values.storeFeatures];
    tempFeatures.push(tempFeatureData);
    setSelectedFeature(null);
    setValues({
      ...values,
      storeFeatures: _.uniqBy(tempFeatures, "serviceId"),
    });
    setIsDataLoading(false);
  };

  const onDeleteService = (index: number) => {
    setIsDataLoading(true);
    const tempFeatures = _.clone(values.storeFeatures) || [];
    const deletedFeatureId = tempFeatures[index];
    tempFeatures.splice(index, 1);

    if (storeData) {
      setValues({
        ...values,
        storeFeatures: tempFeatures,
        deletedStoreFeatureIds: [
          ...values.deletedStoreFeatureIds,
          deletedFeatureId.id,
        ],
      });
    } else {
      setValues({ ...values, storeFeatures: tempFeatures });
    }
    setIsDataLoading(false);
  };

  const handleDayChange = (_: any, newDay: string) => {
    setSelectedDay(newDay);
    if (newDay) {
      const res = values?.storeHours?.find((s: any) => s.day === newDay);
      if (res) {
        const currentDate = new Date().toISOString().slice(0, 10);
        const openDateTimeString = currentDate + `T${res.opensAt}:00`;
        const openDateObject = new Date(openDateTimeString);
        const closeDateTimeString = currentDate + `T${res.closeAt}:00`;
        const closeDateObject = new Date(closeDateTimeString);

        setOpenHours(openDateObject);
        setCloseHours(closeDateObject);
      }
    }
  };

  const onHoursAdd = () => {
    setIsDataLoading(true);
    const tempStoreHours = _.clone(values?.storeHours);
    const res = tempStoreHours.find((s: any) => s.day === selectedDay);
    if (res) {
      const foundIndex = tempStoreHours.findIndex(
        (x: any) => x.day === res.day,
      );
      tempStoreHours[foundIndex] = {
        ...res,
        opensAt: format(openHours, "HH:mm"),
        closeAt: format(closeHours, "HH:mm"),
      };
      setValues({ ...values, storeHours: tempStoreHours });
    }
    setIsDataLoading(false);
  };

  const validateForm = () => {
    const errors: any = {};
    if (!values.storeCode?.trim()) {
      errors.storeCode = "Required";
    }
    if (!values.regionName?.trim()) {
      errors.regionName = "Required";
    }
    if (!values.regionManagerName?.trim()) {
      errors.regionManagerName = "Required";
    }
    if (!values.regionManagerEmail?.trim()) {
      errors.regionManagerEmail = "Required";
    }
    if (!values.districtManagerName?.trim()) {
      errors.districtManagerName = "Required";
    }
    if (!values.districtManagerEmail?.trim()) {
      errors.districtManagerEmail = "Required";
    }
    if (!values.sm1name?.trim()) {
      errors.sm1name = "Required";
    }
    if (!values.sm1email?.trim()) {
      errors.sm1email = "Required";
    }
    if (!values.address1?.trim()) {
      errors.address1 = "Required";
    }
    if (!values.state?.trim()) {
      errors.state = "Required";
    }
    if (!values.city?.trim()) {
      errors.city = "Required";
    }
    if (!values.zipCode?.trim()) {
      errors.zipCode = "Required";
    }
    if (!values.minimumStaffingLevel) {
      errors.minimumStaffingLevel = "Required";
    }
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleClose = () => {
    onCancel();
  };

  const handleSave = async () => {
    try {
      // if (!isEnable && storeData) return null;
      const isValid = validateForm();
      if (isValid) {
        // if (_.isEmpty(values.storeJobs)) {
        //   enqueueSnackbar(`Excluded jobs are required!`, {
        //     variant: "warning",
        //     anchorOrigin: { horizontal: "right", vertical: "top" },
        //   });
        //   return false;
        // } else if (_.isEmpty(values.storeFeatures)) {
        //   enqueueSnackbar(`Services are required!`, {
        //     variant: "warning",
        //     anchorOrigin: { horizontal: "right", vertical: "top" },
        //   });
        //   return false;
        // }
        setIsDataLoading(true);
        const payload: any = {
          createdBy: values.createdBy || "",
          modifiedBy: values.modifiedBy || "",
          createdDate: values.createdDate || "",
          modifiedDate: values.modifiedDate || "",
          storeId: values.storeId || 0,
          storeCode: values.storeCode || "",
          districtId: values.districtId || null,
          districtManagerName: values.districtManagerName || "",
          districtManagerEmail: values.districtManagerEmail || "",
          regionId: values.regionId,
          regionName: values.regionName || "",
          regionManagerName: values.regionManagerName || "",
          regionManagerEmail: values.regionManagerEmail || "",
          sm1name: values.sm1name || "",
          sm1email: values.sm1email || "",
          sm2name: values.sm2name || "",
          sm2email: values.sm2email || "",
          address1: values.address1 || "",
          address2: values.address2 || "",
          city: values.city || "",
          state: values.state || "",
          zipCode: values.zipCode || "",
          opensAt: values.opensAt || "",
          closeAt: values.closeAt || "",
          is24Hours: values.is24Hours || false,
          isActive: values.isActive,
          minimumStaffingLevel: values.minimumStaffingLevel || null,
          storeFeatures: values.storeFeatures || [],
          deletedStoreFeatureIds: values.deletedStoreFeatureIds || [],
          storeJobs: values.storeJobs || [],
          deletedStoreJobsIds: values.deletedStoreJobsIds || [],
          storeHours: values.storeHours || [],
          district: values.district || null,
        };
        if (isEnable && storeData) {
          payload.modifiedBy = account?.username || "";
          payload.modifiedDate = new Date().toISOString();
        } else {
          payload.createdBy = account?.username || "";
          payload.modifiedBy = account?.username || "";
          payload.createdDate = new Date().toISOString();
          payload.modifiedDate = new Date().toISOString();
        }
        const response = await storeService.saveStore(payload);

        // Check if the response status is not 200
        if (response?.data?.status && response?.data?.status !== 200) {
          // Check if there are errors in the response
          if (response?.data?.errors) {
            // Iterate through the dynamic keys in "errors" object
            for (const errorKey in response?.data?.errors) {
              if (
                Object.prototype.hasOwnProperty.call(
                  response?.data?.errors,
                  errorKey,
                )
              ) {
                // Get the error message array
                const errorMessages = response?.data?.errors[errorKey];

                // Display each error message in an alert
                errorMessages.forEach((errorMessage: string) => {
                  enqueueSnackbar(`Error: ${errorMessage}`, {
                    variant: "error",
                    anchorOrigin: { horizontal: "right", vertical: "top" },
                  });
                });
              }
            }
          } else {
            // Display a generic error message if there are no specific errors
            enqueueSnackbar("Error: Something went wrong!", {
              variant: "error",
              anchorOrigin: { horizontal: "right", vertical: "top" },
            });
          }
        } else {
          // Handle success scenario
          enqueueSnackbar("Success: The operation was successful!", {
            variant: "success",
            anchorOrigin: { horizontal: "right", vertical: "top" },
          });
          onSubmit();
        }
        setIsDataLoading(false);
      } else {
        enqueueSnackbar(`Validation failed! Check for required fields.`, {
          variant: "warning",
          anchorOrigin: { horizontal: "right", vertical: "top" },
        });
        return false;
      }
    } catch (error) {
      console.error("[handleSave]: ", error);
      setIsDataLoading(false);
    }
  };

  return (
    <React.Fragment>
      {isDataLoading && <Spinner />}
      <Stack spacing={2}>
        {isLoading ? (
          <Stack direction={"row"} spacing={2} flex={1}>
            <Stack flex={1} height={618}>
              <Skeleton variant="rounded" height={"100%"} width={"100%"} />
            </Stack>

            <Stack flex={0.6} spacing={2}>
              <Skeleton variant="rounded" height={"100%"} sx={{ flex: 1 }} />
              <Skeleton variant="rounded" height={"100%"} sx={{ flex: 1 }} />
            </Stack>
          </Stack>
        ) : (
          <Stack>
            <Typography sx={{ ...INPUT_LABEL, alignSelf: "flex-end" }}>
              * Indicates a required field
            </Typography>
            <Stack direction={"row"} spacing={2} flex={1}>
              <Stack sx={{ ...CONTENT_BOX, height: 618 }} spacing={2}>
                <Stack
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Typography sx={SECTION_HEADER}>Store Overview</Typography>
                  <FormControl variant="outlined">
                    <FormControlLabel
                      disableTypography
                      control={
                        <CustomSwitch
                          sx={{ m: 1 }}
                          checked={values.isActive}
                          onChange={handleInputChange}
                          inputProps={{ "aria-label": "store-toggle" }}
                          name="isActive"
                        />
                      }
                      label={values.isActive ? "Active" : "Inactive"}
                      style={SWITCH_LABEL}
                    />
                  </FormControl>
                </Stack>
                <Stack flex={1} overflow={"auto"} spacing={2}>
                  <Stack direction="row" spacing={1.5}>
                    <CustomInput
                      label="Store Code*"
                      id="storeCode"
                      name="storeCode"
                      placeholder="Enter Code"
                      value={values.storeCode}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        handleInputChange(e);
                        setValidationErrors({
                          ...validationErrors,
                          storeCode: "",
                        });
                      }}
                      error={Boolean(validationErrors.storeCode)}
                      validationText={validationErrors.storeCode}
                    />
                    <CustomSelect
                      label={"ROC*"}
                      id="region"
                      name="region"
                      placeholder="Select Region"
                      options={regions}
                      disabled={currentRole !== UserRole.VP}
                      valueKey="regionId"
                      value={values.regionName}
                      onChangeOption={(val: any) => {
                        handleRegionChange(val);
                        setValidationErrors({
                          ...validationErrors,
                          regionName: "",
                        });
                      }}
                      error={Boolean(validationErrors.regionName)}
                      validationText={validationErrors.regionName}
                    />
                  </Stack>
                  <Stack direction="row" spacing={1.5}>
                    <CustomSelect
                      label={"RM Manager*"}
                      id="regionManagerName"
                      name="regionManagerName"
                      placeholder="Select Manager"
                      disabled={currentRole !== UserRole.VP}
                      options={filteredManagers?.rmUsers || []}
                      valueKey="regionId"
                      displayKey="managerName"
                      value={values.regionManagerName}
                      onChangeOption={(val: any) => {
                        handleRMChange(val);
                        setValidationErrors({
                          ...validationErrors,
                          regionManagerName: "",
                        });
                      }}
                      error={Boolean(validationErrors.regionManagerName)}
                      validationText={validationErrors.regionManagerName}
                    />
                    <CustomInput
                      label="RM Email*"
                      id="regionManagerEmail"
                      name="regionManagerEmail"
                      placeholder="Enter Email"
                      value={values.regionManagerEmail}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        handleInputChange(e);
                        setValidationErrors({
                          ...validationErrors,
                          regionManagerEmail: "",
                        });
                      }}
                      readOnlyInput={true}
                      error={Boolean(validationErrors.regionManagerEmail)}
                      validationText={validationErrors.regionManagerEmail}
                    />
                  </Stack>
                  <Stack direction="row" spacing={1.5}>
                    <CustomSelect
                      label={"DM Manager*"}
                      id="districtManagerName"
                      name="districtManagerName"
                      placeholder="Select Manager"
                      options={filteredManagers?.dmUsers || []}
                      valueKey="displayName"
                      displayKey="displayName"
                      value={values.districtManagerName}
                      onChangeOption={(val: any) => {
                        handleDMChange(val);
                        setValidationErrors({
                          ...validationErrors,
                          districtManagerName: "",
                        });
                      }}
                      error={Boolean(validationErrors.districtManagerName)}
                      validationText={validationErrors.districtManagerName}
                    />
                    <CustomInput
                      label="DM Email*"
                      id="districtManagerEmail"
                      name="districtManagerEmail"
                      placeholder="Enter Email"
                      value={values.districtManagerEmail}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        handleInputChange(e);
                        setValidationErrors({
                          ...validationErrors,
                          districtManagerEmail: "",
                        });
                      }}
                      readOnlyInput={true}
                      error={Boolean(validationErrors.districtManagerEmail)}
                      validationText={validationErrors.districtManagerEmail}
                    />
                  </Stack>
                  <Stack direction="row" spacing={1.5}>
                    {/* <CustomSelect
                      label={"Store Manager*"}
                      id="sm1name"
                      name="sm1name"
                      placeholder="Select Manager"
                      options={filteredManagers?.smUsers || []}
                      valueKey="displayName"
                      displayKey="displayName"
                      value={values.sm1name}
                      onChangeOption={(val: any) => {
                        handleSM1Change(val);
                        setValidationErrors({
                          ...validationErrors,
                          sm1name: "",
                        });
                      }}
                      error={Boolean(validationErrors.sm1name)}
                      validationText={validationErrors.sm1name}
                    /> */}
                    <CustomInput
                      label="Store Manager*"
                      id="sm1name"
                      name="sm1name"
                      placeholder="Enter Name"
                      value={values.sm1name}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        handleInputChange(e);
                        setValidationErrors({
                          ...validationErrors,
                          sm1name: "",
                        });
                      }}
                      error={Boolean(validationErrors.sm1name)}
                      validationText={validationErrors.sm1name}
                    />
                    <CustomInput
                      label="Store Manager Email*"
                      id="sm1email"
                      name="sm1email"
                      placeholder="Enter Email"
                      value={values.sm1email}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        handleInputChange(e);
                        setValidationErrors({
                          ...validationErrors,
                          sm1email: "",
                        });
                      }}
                      error={Boolean(validationErrors.sm1email)}
                      validationText={validationErrors.sm1email}
                    />
                  </Stack>
                  <Stack direction="row" spacing={1.5}>
                    <CustomSelect
                      label={"Store Account"}
                      id="sm2name"
                      name="sm2name"
                      placeholder="Select Manager"
                      options={filteredManagers?.smUsers || []}
                      valueKey="displayName"
                      displayKey="displayName"
                      value={values.sm2name}
                      onChangeOption={handleSM2Change}
                    />
                    <CustomInput
                      label="Store Account Email"
                      id="sm2email"
                      name="sm2email"
                      placeholder="Enter Email"
                      value={values.sm2email}
                      onChange={handleInputChange}
                      readOnlyInput={true}
                    />
                  </Stack>
                  <CustomInput
                    label="Address 1*"
                    id="address1"
                    name="address1"
                    placeholder="Enter Address"
                    value={values.address1}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleInputChange(e);
                      setValidationErrors({
                        ...validationErrors,
                        address1: "",
                      });
                    }}
                    error={Boolean(validationErrors.address1)}
                    validationText={validationErrors.address1}
                  />
                  <CustomInput
                    label="Address 2"
                    id="address2"
                    name="address2"
                    placeholder="Enter Address"
                    value={values.address2}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleInputChange(e);
                    }}
                  />
                  <Stack direction="row" spacing={1.5}>
                    <CustomInput
                      label="State*"
                      id="state"
                      name="state"
                      placeholder="Enter State"
                      value={values.state}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        handleInputChange(e);
                        setValidationErrors({
                          ...validationErrors,
                          state: "",
                        });
                      }}
                      error={Boolean(validationErrors.state)}
                      validationText={validationErrors.state}
                    />
                    <CustomInput
                      label="City*"
                      id="city"
                      name="city"
                      placeholder="Enter City"
                      value={values.city}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        handleInputChange(e);
                        setValidationErrors({
                          ...validationErrors,
                          city: "",
                        });
                      }}
                      error={Boolean(validationErrors.city)}
                      validationText={validationErrors.city}
                    />
                    <CustomInput
                      label="Zipcode*"
                      id="zipCode"
                      name="zipCode"
                      placeholder="Enter Zipcode"
                      value={values.zipCode}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        handleInputChange(e);
                        setValidationErrors({
                          ...validationErrors,
                          zipCode: "",
                        });
                      }}
                      error={Boolean(validationErrors.zipCode)}
                      validationText={validationErrors.zipCode}
                    />
                  </Stack>
                  <Divider variant="inset" />
                  <Stack spacing={2}>
                    <Typography sx={INPUT_LABEL}>Store Hours*</Typography>
                    <ToggleButtonGroup
                      value={selectedDay}
                      onChange={handleDayChange}
                      exclusive
                      aria-label="weekday-selector"
                    >
                      {weekdays.map((day, index) => (
                        <WeekdayToggle key={index} value={day}>
                          {day.slice(0, 3)}
                        </WeekdayToggle>
                      ))}
                    </ToggleButtonGroup>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      {selectedDay && (
                        <Stack direction={"row"} spacing={1.5}>
                          <TimeField
                            label="Opens At"
                            value={openHours}
                            format="HH:mm"
                            onChange={(newValue) => setOpenHours(newValue)}
                            size="small"
                            sx={{ width: 84 }}
                          />
                          <TimeField
                            label="Close At"
                            value={closeHours}
                            format="HH:mm"
                            onChange={(newValue) => setCloseHours(newValue)}
                            size="small"
                            sx={{ width: 84 }}
                          />
                          <Button
                            startIcon={<Add />}
                            sx={ADD_BTN}
                            onClick={onHoursAdd}
                          >
                            Add
                          </Button>
                        </Stack>
                      )}
                    </LocalizationProvider>
                  </Stack>
                  <Divider variant="inset" />
                  <CustomInput
                    label="Minimum Staffing Standards*"
                    id="minimumStaffingLevel"
                    name="minimumStaffingLevel"
                    placeholder="Enter Value"
                    value={values.minimumStaffingLevel || ""}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleInputChange(e, FLOAT_REGEX);
                      setValidationErrors({
                        ...validationErrors,
                        minimumStaffingLevel: "",
                      });
                    }}
                    error={Boolean(validationErrors.minimumStaffingLevel)}
                    validationText={validationErrors.minimumStaffingLevel}
                  />
                </Stack>
              </Stack>
              <Stack spacing={2} flex={0.8}>
                <Stack sx={CONTENT_BOX} spacing={2}>
                  <Typography sx={SECTION_HEADER}>Excluded Jobs</Typography>
                  <Stack direction={"row"} alignItems={"center"} spacing={1.5}>
                    <CustomSelect
                      id="jobServices"
                      name="jobServices"
                      placeholder="Select..."
                      options={services || []}
                      valueKey="serviceId"
                      displayKey="name"
                      value={newJob.serviceName}
                      onChangeOption={handleServiceChange}
                    />

                    <CustomSelect
                      id="jobRoles"
                      name="jobRoles"
                      placeholder="Select..."
                      options={jobs || []}
                      valueKey="jobId"
                      displayKey="name"
                      value={newJob.jobName}
                      onChangeOption={handleJobChange}
                    />
                    <Button
                      startIcon={<Add />}
                      sx={ADD_BTN}
                      disabled={!newJob.serviceName || !newJob.jobName}
                      onClick={onJobAdd}
                    >
                      Add
                    </Button>
                  </Stack>
                  {/* <Divider variant="inset" /> */}
                  <TableContainer sx={{ height: 200, overflow: "auto" }}>
                    <Table aria-label="jobs table">
                      <TableBody>
                        {values.storeJobs?.length > 0 ? (
                          values.storeJobs?.map((row: any, index: number) => (
                            <TableRow
                              sx={{
                                ...TABLE_ROW_WRAPPER,
                                borderColor: "#E6F5F6",
                                "&:nth-of-type(even)": {
                                  backgroundColor: "rgba(249, 249, 250, 0.60)",
                                },
                              }}
                              key={`jobs-row-${index}`}
                            >
                              <TableCell
                                sx={{
                                  ...TABLE_CELL_WRAPPER,
                                  borderColor: "#E6F5F6",
                                  color: "#0F3D61",
                                }}
                                size="small"
                              >
                                <Stack
                                  direction={"row"}
                                  alignItems={"center"}
                                  gap={"10px"}
                                >
                                  <img
                                    src={CHECK_CIRCLE_ICON}
                                    alt="check-circle"
                                  />
                                  {row?.serviceName || "-"}
                                </Stack>
                              </TableCell>
                              <TableCell
                                sx={{
                                  ...TABLE_CELL_WRAPPER,
                                  borderColor: "#E6F5F6",
                                  color: "#0F3D61",
                                }}
                                size="small"
                              >
                                {row?.jobName || "-"}
                              </TableCell>
                              <TableCell size="small">
                                <IconButton
                                  onClick={() => onJobDelete(index)}
                                  size="small"
                                >
                                  <img
                                    src={DELETE_ICON}
                                    alt="delete"
                                    width={16}
                                  />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow sx={TABLE_ROW_WRAPPER}>
                            <TableCell align="center">
                              <Stack
                                justifyContent={"center"}
                                alignItems={"center"}
                                flex={1}
                                spacing={1}
                              >
                                <WorkOffOutlined
                                  fontSize="small"
                                  sx={{ color: "rgba(6, 30, 35, 0.34)" }}
                                />
                                <Typography sx={NO_DATA_TEXT}>
                                  No jobs added!
                                </Typography>
                              </Stack>
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Stack>
                <Stack sx={{ ...CONTENT_BOX, flex: 0.5 }} spacing={2}>
                  <Typography sx={SECTION_HEADER}>Services</Typography>
                  <Stack direction={"row"} alignItems={"center"} spacing={1.5}>
                    <CustomSelect
                      id="features"
                      name="features"
                      placeholder="Select..."
                      options={services || []}
                      valueKey="serviceId"
                      displayKey="name"
                      value={selectedFeature?.name}
                      onChangeOption={(val: any) => setSelectedFeature(val)}
                    />
                    <Button
                      startIcon={<Add />}
                      sx={ADD_BTN}
                      disabled={!selectedFeature}
                      onClick={onFeatureAdd}
                    >
                      Add
                    </Button>
                  </Stack>
                  <Divider variant="inset" />
                  <Stack
                    direction={"row"}
                    flexWrap={"wrap"}
                    height={96}
                    overflow={"auto"}
                  >
                    {values.storeFeatures.length > 0 ? (
                      values.storeFeatures.map(
                        (service: any, index: number) => (
                          <Chip
                            key={`service-${index}`}
                            sx={CHIP_WRAPPER}
                            icon={
                              <img src={CHECK_CIRCLE_ICON} alt="check-circle" />
                            }
                            label={service?.serviceName || ""}
                            size="small"
                            onDelete={() => onDeleteService(index)}
                            deleteIcon={<Close />}
                          />
                        ),
                      )
                    ) : (
                      <Stack
                        justifyContent={"center"}
                        alignItems={"center"}
                        flex={1}
                        spacing={1}
                      >
                        <WorkOffOutlined
                          fontSize="small"
                          sx={{ color: "rgba(6, 30, 35, 0.34)" }}
                        />
                        <Typography sx={NO_DATA_TEXT}>
                          No services added!
                        </Typography>
                      </Stack>
                    )}
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        )}
        <Stack direction={"row"} justifyContent={"flex-end"} spacing={1.5}>
          <Button sx={CANCEL_BTN} onClick={handleClose}>
            Cancel
          </Button>
          <Button
            startIcon={<SaveOutlined />}
            sx={SAVE_BTN}
            onClick={handleSave}
          >
            Save Store
          </Button>
        </Stack>
      </Stack>
    </React.Fragment>
  );
};

export default CreateStoreForm;
