import {
  ToggleButtonGroup,
  ToggleButton,
  Stack,
  SxProps,
  Typography,
} from "@mui/material";
import { CONTENT_BOX, CONTENT_BOX_TITLE } from "./styles";
import React from "react";
import _ from "lodash";
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Label,
  LabelList,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { HideImageOutlined } from "@mui/icons-material";
import { getColourByRole } from "../../utils/helpers";

const DAYS = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const DAY_BTN: SxProps = {
  padding: "4px 12px",
  borderRadius: "4px !important",
  border: "1px solid rgba(173, 175, 178, 0.50) !important",
  background: "#FFFFFF",
  fontSize: 12,
  fontWeight: 500,
  lineHeight: "24px",
  color: "#595959",
  textTransform: "capitalize",
  "&.Mui-selected": {
    borderColor: "#0696A6 !important",
    background: "#E6EFF1",
    color: "#383838",
  },
};

// Custom Tooltip Component
const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    const forecastedValue = payload[0]?.payload?.forecasted || null;

    return (
      <Stack
        spacing={0.5}
        sx={{
          backgroundColor: "white",
          padding: 1,
          border: "1px solid #D8D8D9",
        }}
      >
        <Stack spacing={0.5}>
          <Typography
            sx={{ alignSelf: "center", fontSize: 14, fontWeight: 500 }}
          >
            Total
          </Typography>
          <Typography sx={{ fontSize: 14 }}>{`Time: ${label}`}</Typography>
        </Stack>
        {payload.map((item: any, index: number) => (
          <Typography
            key={`tooltip-item-${index}`}
            sx={{
              textTransform: "capitalize",
              fontSize: 14,
              color: item.color,
            }}
          >
            {`${item.name}: ${
              Number(item.value) === item.value && item.value % 1 !== 0
                ? item.value.toFixed(1)
                : item.value
            }`}
          </Typography>
        ))}
        {forecastedValue && (
          <Typography sx={{ fontSize: 14 }}>{`Forecasted Transactions: ${
            Number(forecastedValue) === forecastedValue &&
            forecastedValue % 1 !== 0
              ? forecastedValue.toFixed(1)
              : forecastedValue
          }`}</Typography>
        )}
      </Stack>
    );
  }

  return null;
};

const ScheduleChart = ({ chartData }: any) => {
  const [selectedDay, setSelectedDay] = React.useState<number>(0);
  const [multiChartData, setMultiChartData] = React.useState<any[]>([]);

  const handleChange = (
    _event: React.MouseEvent<HTMLElement>,
    newDay: number,
  ) => {
    setSelectedDay(newDay);
  };

  React.useEffect(() => {
    if (chartData?.length > 0) {
      const tempChartData: any = [];
      chartData.forEach((data: any) => {
        const tempDayData: any = [];
        const roles = Object.keys(data.data);
        data?.time?.forEach((t: string, tIndex: number) => {
          const tempItem: any = {
            time: t,
          };
          roles.forEach((k) => {
            tempItem[k] = data?.data?.[k]?.[tIndex] || null;
          });
          tempItem["recommendedPeople"] =
            data.recommendedPeople[tIndex] || null;
          tempItem["forecasted"] = data.forecasted[tIndex] || null;
          tempDayData.push(tempItem);
        });
        const tempDayItem = {
          day: data?.scheduleDay,
          data: tempDayData,
          roles: roles,
        };
        tempChartData.push(tempDayItem);
      });
      setMultiChartData(tempChartData);
    }
  }, [chartData]);

  return (
    <Stack sx={{ ...CONTENT_BOX, minHeight: 450 }} spacing={1.5}>
      <Stack direction={"row"} justifyContent={"flex-end"}>
        <ToggleButtonGroup
          value={selectedDay}
          exclusive
          onChange={handleChange}
          aria-label="Days"
          sx={{ gap: 1 }}
        >
          {DAYS.map((day: string, index: number) => (
            <ToggleButton
              value={index}
              key={`days_${index}`}
              size="small"
              sx={DAY_BTN}
            >
              {day}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </Stack>
      {!_.isEmpty(multiChartData && multiChartData[selectedDay]?.data) ? (
        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart
            data={multiChartData[selectedDay].data}
            margin={{
              top: 20,
              right: 0,
              left: 0,
              bottom: 0,
            }}
          >
            <CartesianGrid stroke="#f5f5f5" />
            <XAxis
              dataKey="time"
              scale="point"
              className="chart-axis-text"
              interval={59}
              angle={270}
              tickMargin={15}
              // padding={{right: 30}}
            >
              {/* <Label value="Time" position="insideBottom" offset={-10} /> */}
            </XAxis>
            <YAxis
              yAxisId="left"
              className="chart-axis-text"
              allowDecimals={false}
              scale="linear"
            >
              <Label
                value="# of People"
                position="insideLeft"
                offset={10}
                angle={270}
              />
            </YAxis>
            {/* <YAxis
              yAxisId="right"
              orientation="right"
              className="chart-axis-text"
              padding={{ top: 50 }}
            >
              <Label
                value="Forecasted Transactions"
                position="insideBottomRight"
                offset={15}
                angle={90}
              />
            </YAxis> */}
            <Tooltip
              // contentStyle={{ textTransform: "capitalize", fontSize: 14 }}
              // filterNull
              // labelFormatter={(label) => (
              //   <Stack spacing={0.5}>
              //     <Typography
              //       sx={{ alignSelf: "center", fontSize: 14, fontWeight: 500 }}
              //     >
              //       Total
              //     </Typography>
              //     <Typography
              //       sx={{ fontSize: 14 }}
              //     >{`Time: ${label}`}</Typography>
              //   </Stack>
              // )}
              // formatter={(value) =>
              //   Number(value) === value && value % 1 !== 0
              //     ? value.toFixed(1)
              //     : value
              // }
              content={<CustomTooltip />}
              isAnimationActive={false}
            />
            <Legend
              formatter={(value) =>
                value === "Recommended # People"
                  ? value
                  : _.startCase(_.lowerCase(value))
              }
              wrapperStyle={{ fontSize: 14, paddingTop: 8 }}
              iconType="square"
            />
            {multiChartData[selectedDay]?.roles?.map(
              (role: string, index: number) => {
                const areaColor = getColourByRole(role, index);

                // Check if there is at least one non-null value in the role data array
                const isRoleVisible = multiChartData[selectedDay]?.data?.some(
                  (item: any) => item[role] !== null,
                );
                return (
                  isRoleVisible && (
                    <Area
                      key={`area-${index}`}
                      type="linear"
                      yAxisId={"left"}
                      stackId="1"
                      dataKey={role}
                      fill={areaColor}
                      stroke={areaColor}
                      animationDuration={300}
                    />
                  )
                );
              },
            )}
            {multiChartData[selectedDay]?.data?.some(
              (value: any) => value?.recommendedPeople !== null,
            ) && (
              <Line
                type="monotone"
                yAxisId={"left"}
                dataKey="recommendedPeople"
                stroke="#032e44"
                strokeWidth={2}
                animationDuration={300}
                connectNulls
                legendType="none"
                name="Recommended # People"
                dot={{ r: 3 }}
              >
                <LabelList
                  dataKey="recommendedPeople"
                  position="top"
                  offset={10}
                  formatter={(value: any) =>
                    Number(value) === value && value % 1 !== 0
                      ? value.toFixed(1)
                      : value
                  }
                />
              </Line>
            )}
          </ComposedChart>
        </ResponsiveContainer>
      ) : (
        <Stack
          justifyContent={"center"}
          alignItems={"center"}
          flex={1}
          spacing={1}
        >
          <HideImageOutlined
            fontSize="large"
            sx={{ color: "rgba(6, 30, 35, 0.34)" }}
          />
          <Typography sx={{ ...CONTENT_BOX_TITLE, color: "#595959" }}>
            No data available!
          </Typography>
        </Stack>
      )}
    </Stack>
  );
};

export default ScheduleChart;
